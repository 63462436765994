import * as _ from '@proftit/lodash';
import moment from 'moment-timezone';

import TableLiveController from '~/source/common/components/table/table-live.controller';
import { dashboardSettings } from './dashboard-settings';
import CustomersService from '~/source/contact/common/services/customers';
import BrandsService from '~/source/management/brand/services/brands';
import UsersService from '~/source/management/user/services/users';
import { TokensService } from '~/source/auth/services/tokens';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import {
  Brand,
  ContactsImport,
  Customer,
  Desk,
  User,
} from '@proftit/crm.api.models.entities';
import CustomersSocket from '~/source/contact/common/services/customers-socket';
import AuthenticationService from '~/source/auth/services/authentication';
import numRowsOptions from '~/source/common/components/dropdowns/rows-options';
import UserSettings from '~/source/common/services/user-settings';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

import template from './contacts-dashboard.html';
import { ContactDashboardCacheService } from '../../common/services/contact-dashboard-cache.service';
import { ModalService } from '~/source/common/components/modal/modal.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  CONTACT_ADDED,
  USER_SETTINGS_UPDATED,
} from '~/source/common/constants/general-pubsub-keys';

import * as rx from '@proftit/rxjs';
import ng, { IPromise, blockUI } from 'angular';
import Url from 'url-parse';
import { CRMExportService } from '~/source/common/services/crm-export.service';
import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { ContactsImportsService } from '~/source/common/api-crm-server/services/contacts-imports.service';
import { CommunicationTypesService } from '~/source/common/services/communication-types.service';
import PopupService from '~/source/common/components/modal/popup.service';

import { CommunicationMethodCode } from '@proftit/crm.api.models.enums';
import statusUpdateTemplate from '~/source/contact/contact-page/calls/customer-status-update-popup.html';
import { StateService } from '@uirouter/core';
import { generateUuid } from '@proftit/general-utilities/';
import { FormControl } from '@proftit/ng1.reactive-forms';

const styles = require('./contacts-dashboard.component.scss');
const CONTACTS_IMPORT_FILTER_NAME = 'contactsImport';
const BRAND_FILTER_NAME = 'contactDashboardBrandSingle';
const USER_SETTING_CONTACT_DASHBOARD_COLUMNS_VERSION = 3;
const USER_SETTING_CONTACT_DASHBOARD_COLUMNS_KEY = 'contactsTableColumnsV2';
class ContactsDashboardController extends TableLiveController {
  static $inject = [
    '$scope',
    'dateFormat',
    'appConfig',
    'customersService',
    'prfCRMExportService',
    'authenticationService',
    'tradeReputationTypesService',
    'stats',
    'customersSocketService',
    'tokensService',
    'highlightEntityService',
    'brandsService',
    'usersService',
    'contactDashboardCacheService',
    'userSettingsService',
    'popupService',
    'modalService',
    'prfClientGeneralPubsub',
    '$translate',
    'PermPermissionStore',
    'prfContactsImportsService',
    'communicationTypesService',
    'popupService',
    '$state',
    'prfAppTag',
    'blockUI',
    ...TableLiveController.$inject,
  ];

  onTableInit: () => void;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customersService: () => CustomersService;
  prfCRMExportService: () => CRMExportService;
  settings: any; // todoOld: type json specific when typescript will enable it
  dataServiceInstance: CustomersService;
  brandsServiceInstance: BrandsService;
  user: UserTokenModel;
  customersServiceInstance: CustomersService;
  usersServiceInst: UsersService;
  isAssignOpen: { status: boolean };
  quickFilters: any;
  filterModels: any;
  showSelectionBar: boolean;
  selectedRows: any[];
  tableColumns: any[];
  brandCacheKey: string;
  tokensService: TokensService;
  brandsService: () => BrandsService;
  usersService: () => UsersService;
  selectedBrand: IElementRestNg<Brand>;
  tblNumOfRows: any;
  commonPlatformType: string;
  customers: IElementRestNg<Customer>[];
  allowRowsSelection: boolean;
  customersSocketService: CustomersSocket;
  authenticationService: AuthenticationService;
  userVoipBrands: Set<number>;
  contactDashboardCacheService: ContactDashboardCacheService;
  userSettingsService: UserSettings;
  userSettingsInstance: any;
  modalService: ModalService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  exportTableCsvAction = new rx.Subject<void>();
  $translate: ng.translate.ITranslateService;
  PermissionNormalized = PermissionNormalized;
  PermPermissionStore: ng.permission.PermissionStore;
  $state: StateService;
  prfAppTag: any;
  blockUI: blockUI.BlockUIService;

  contactsSearchTerm$ = new FormControl<string>(null);

  Permissions = Permissions;

  isAllowedToSeeAndExportCsv$ = this.streamIsAllowedToSeeAndExportCsv();
  prfContactsImportsService: () => ContactsImportsService;

  communicationTypesService: CommunicationTypesService;
  popupService: PopupService;

  addNewCallAction = new rx.Subject<IElementRestNg<Customer>>();

  opAddFilterToTable$ = new rx.Subject<{
    clearName: string;
    newFilter: Object;
  }>();

  opRemoveFilterFromTable$ = new rx.Subject<string>();

  tableFilterRemovedByUi$ = new rx.Subject<string>();
  tableFilterAddedByEvent$ = new rx.Subject<any>();
  tableFilterAppliedFromSavedCache$ = new rx.Subject<any>();
  savedFilterChanged$ = new rx.Subject<any>();
  tableFiltersClearedByBar$ = new rx.Subject<void>();
  mainBrandSelectDataFetched$ = new rx.Subject<Brand[]>();

  allBrandsOption$ = this.streamAllBrandsOption();

  editColumnsAction = new rx.Subject();
  filteredSettings = [];

  constructor(...args) {
    super(...args);

    Object.assign(this, {
      settings: dashboardSettings,
      dataServiceInstance: this.customersService(),
      brandsServiceInstance: this.brandsService(),
      user: this.tokensService.getCachedUser(),
      customersServiceInstance: this.customersService(),
      usersServiceInst: this.usersService(),
      // it's an object so that we could pass the variable by reference and use one way binding
      isAssignOpen: { status: false },
      quickFilters: Object.assign(
        {},
        (<any>dashboardSettings).contactsTable.quickFilters,
      ),
      filterModels: {},
      /*
       * set custom logic that used with checkbox and paging events:
       * hide contact assign popup.
       */
      showSelectionBar: false,
      // selected (checked) rows in customers table
      selectedRows: [],
      tableColumns: [],
      brandCacheKey: `${this.tableKey}Brand`,
    });
  }

  $onInit() {
    super.$onInit();

    useStreams(
      [
        this.contactsSearchTerm$.value$,
        this.streamOnSearchTermChange(),
        this.streamRefreshAfterContactAdd(),
        this.streamExportTableCsv(),
        this.streamOnTableInit(),
        this.streamContactsImportFilter(),
        this.streamAddNewCall(),
        this.streamOpenEditColumnsPopup(),
        this.streamUserCoulomnChanged(),
        this.streamSyncFilterRemovedByFilterBarUi(),
        this.streamSyncFilterAddedByFilterBarEvent(),
        this.streamSyncMainBrandFilterByTableSavedFilters(),
        this.streamSyncMainBrandFilterByTableAppliedCached(),
        this.streamSyncMainBrandFilterByBarClearingFilters(),
      ],
      this.lifecycles.onDestroy$,
    );

    this.tblNumOfRows = {
      count: this.settings.contactsTable.ngTable.parameters.count,
    };
    this.handleUrlFilterParam();
    this.getBrandsList()
      .then((brands) => {
        // should be null by default in order to select 'all brands' in brand dropdown
        this.selectedBrand =
          (brands.length === 1 ? brands[0] : this.getBrandLocalStorage()) ||
          null;

        if (!_.isNil(this.selectedBrand)) {
          this.notifyFilterBarOnBrandFilterUpdate(this.selectedBrand);
        }

        this.commonPlatformType = this.brandsServiceInstance.detectCommonPlatformType(
          brands,
        );

        // add new checkbox column in first place in table for NgTable that use Checkbox.
        this.prepareTableColumnLists();

        const userSettingsPromise = this.userSettingsService
          .getSetting('contactDashboardPageSize', 50)
          .then((res) => {
            this.userSettingsInstance = res;
            this.tblNumOfRows = Object.assign(
              {},
              {
                count: res.value,
                id: numRowsOptions.indexOf(res.value),
                name: res.value,
              },
            );
            this.initTable(this.tblNumOfRows.count);
            return res;
          });
        return Promise.all([Promise.resolve(brands), userSettingsPromise]);
      })
      .then(() => {
        // set user voip brands and add ctc column
        this.setVoipSettings();

        this.$scope.$watchGroup(
          [
            'vm.assignLimit',
            'vm.isAssignOpen.status',
            'vm.checkboxes.selectAllItems',
          ],
          this.toggleCheckboxVisibility.bind(this),
        );

        // on any filter change, call a method to set it to the table
        this.$scope.$watch('vm.selectedBrand', this.onBrandChange.bind(this));

        // listen to assign to changes
        this.$scope.$on('contact:assignToUser:updated', (event, data = {}) => {
          // reset all checkboxes
          this.resetAllCheckboxesSelection();
          /*
           * using streamer is our favoured way to get updates. but we have a problem because the streamer sends
           * simple object without the extended relations (userId). currently the only way is to reload the table
           * after the assign action completed
           * if {unassign: true} object is passed in the event, the table won't reload.
           * @todoOld: remove this workaround when possible which depends on api ability to pass object with extended data
           */
          if (!data.unassign) {
            this.reloadTable();
          }
        });

        // listen to customer statuses changes
        this.$scope.$on('contact:customerStatuses:updated', () => {
          // reset all checkboxes
          this.resetAllCheckboxesSelection();
        });

        this.$scope.$on('table:column:removed', (scope, field) => {
          this.hideColumn(field);
          this.removeFieldFromUserSettings(field);
        });
      });
  }

  streamOnTableInit() {
    return this.isInitTable$.pipe(
      rx.filter((x: boolean) => x),
      rx.tap(() => {
        this.onTableInit();
      }),
    );
  }

  streamOnSearchTermChange() {
    return rx.pipe(
      () => this.contactsSearchTerm$.value$,
      rx.filter((x) => !_.isNil(x)),
      rx.tap((searchTerm) => {
        this.tableParams.page(1);
        if (!_.isNil(searchTerm) && searchTerm.length > 0) {
          this.tableParams.filter().q = searchTerm;
        } else {
          this.tableParams.filter().q = undefined;
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onDestroy() {}

  streamRefreshAfterContactAdd() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((msg) => msg.key === CONTACT_ADDED),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamIsAllowedToSeeAndExportCsv() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.ContactsCrmexport,
          this.PermPermissionStore,
        ),
      rx.map((perms) => perms.isView && perms.isCreate),
      shareReplayRefOne(),
    )(null);
  }

  get tableKey() {
    return 'contacts';
  }

  /**
   * Detects the current platform type: binary/forex/common
   * @return {Promise} promise which resolves to string
   */
  getPlatformType() {
    return this.selectedBrand && this.selectedBrand.id
      ? // brand was selected. use its type
        this.selectedBrand.platformType.code
      : // "all brands" selected. use the detected common type
        this.commonPlatformType;
  }

  getBrandLocalStorage() {
    return this.contactDashboardCacheService.get(this.brandCacheKey);
  }

  setBrandLocalStorage() {
    this.contactDashboardCacheService.put(this.brandCacheKey, {
      id: this.selectedBrand.id,
      name: this.selectedBrand.name,
      platformType: {
        code: this.selectedBrand.platformType
          ? this.selectedBrand.platformType.code
          : null,
      },
    });
  }

  /**
   * Fetches all the brands and sets the 'brands' property.
   * If no brands are found, throws an exception
   *
   * @throws Error when no brands exist
   * @return {Promise} resolves to brands array
   */
  getBrandsList() {
    return this.brandsServiceInstance
      .expand(['platformType', 'platformConnections.platform'])
      .embed(['platformConnections'])
      .getListWithQuery()
      .then((brands) => {
        if (brands.length < 1) {
          throw new Error('No brands exist');
        }

        return brands;
      });
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .expand([
        'brand',
        'brand.attachmentsVerifiedMethod',
        'country',
        'desk',
        'user',
        'lastCommunicationUser',
        'campaign',
        'communicationStatus',
        'migratedStatus',
        'tradeReputation',
        'customerStatus',
        'customerComplianceStatus',
        'customerAccountStatus',
        'customerPropertyValues.property',
        'brand.voipCredentials.voipProvider',
        'brand.smsCredentials.smsProvider',
        'language',
      ])
      .embed([
        'customerPropertyValues',
        'brand.voipCredentials',
        'brand.smsCredentials',
      ])
      .setConfig({ blockUiRef: 'contactTableBlock' });
  }

  /**
   * add brand add to filter api call, without interfere with with filter cache, table cache & initTable methods
   * @returns {Object} return normalized filter for api call with brandId value if exists
   */
  get requiredApiFilters() {
    const filters: any = {};
    if (
      !_.isNil(this.contactsSearchTerm$.value) &&
      this.contactsSearchTerm$.value.length > 0
    ) {
      filters.q = this.contactsSearchTerm$.value;
    }
    return filters;
  }

  parseLoadedData(data, total) {
    // Add manager full name to the data (we need as a single column)
    data.forEach((customer) => {
      const user = customer.user;
      customer._calculatedLastCommunicationDetails = this.getCommunicationDetails(
        customer.lastCommunicationDetails,
      );
      if (_.isEmpty(user)) {
        return;
      }

      customer.managerName = `${user.firstName} ${user.lastName}`;
    });

    // Calculate voip enabled for each user+customer-brand.
    data.forEach((customer) => {
      this.usersService()
        .isVoipEnabledForUser(this.user.id as number, customer.brand.id)
        .then((isEnabled) => (customer._calcIsVoipEnabledForUser = isEnabled));
    });

    data.forEach((customer, index) => {
      customer._customerLinkData = this.getCustomerLinkData(
        customer.id,
        index,
        total,
      );
    });

    this.customers = data;

    return data;
  }

  getCommunicationDetails(lastCommunicationDetails: string) {
    let detailsObject;
    try {
      detailsObject = JSON.parse(lastCommunicationDetails);

      // json parse string number does not throw.
      if (_.isFinite(detailsObject)) {
        return lastCommunicationDetails;
      }
    } catch (e) {
      return lastCommunicationDetails;
    }
    if (_.isNil(detailsObject) || _.isNil(detailsObject.snippet)) {
      return '';
    }
    return _.unescape(detailsObject.snippet);
  }

  /**
   * toggle visibility of assign to component
   */
  toggleAssign() {
    this.isAssignOpen.status = !this.isAssignOpen.status;

    // handle visibility of checkboxes
    this.toggleCheckboxVisibility();
  }

  /**
   * Toggle row selection
   * Overrides the default implementation to disable the selection when needed
   * @param {object} row - selected row
   */
  toggleItem(row, event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.allowRowsSelection) {
      super.toggleItem(row, event);
    }
  }

  /**
   * decide whether to show/hide checkboxes
   * 1. hide checkboxes if brand is not selected
   * 2. hide checkboxes if user selection is in batch mode & assign window is opened
   */
  toggleCheckboxVisibility() {
    // only allow to select a row when the following conditions are met:
    this.allowRowsSelection =
      this.selectedBrand &&
      this.selectedBrand.id && // a brand is selected
      !(this.checkboxes.selectAllItems && this.isAssignOpen.status); // not 'batch' while assign is open

    // set it to the checkbox visibility
    this.tableColumns.find(
      (col) => col.field === 'selector',
    ).show = this.allowRowsSelection;
  }

  /**
   * Checks whether the current column is filterable (needed for desk check -
   * should be filterable only after choosing brand)
   * @param {Object} $column - ngTable column
   * @returns {boolean}
   */
  isColumnFilterable($column) {
    let isFilterable = true;
    const value = this.settings.dependenciesPerFilter[$column.field];

    if (value) {
      _.each((item) => {
        if (!_.isNil(_.get(item, this)) && _.isNil(_.get([item, 'id'], this))) {
          isFilterable = false;
        }
      }, value);
      return isFilterable;
    }

    return super.isColumnFilterable($column);
  }

  /**
   * called when any of the filter changes (either the quick filters or the advanced).
   * Merge all the filters and set them to the table params, which will in turn make the API call
   * with the filters.
   */
  onFiltersChange(event, filterModels) {
    super.onFiltersChange(event, filterModels);

    // reset all checkbox selection
    this.resetAllCheckboxesSelection();
  }

  /**
   * called when brand filter change -
   * use it to show/hide checkboxes column in customers table.
   */
  onBrandChange(nVal: Brand, oVal: Brand) {
    if (oVal === undefined || nVal === oVal) {
      return;
    }

    this.notifyFilterBarOnBrandFilterUpdate(nVal);

    this.prepareTableColumnLists();

    this.setBrandLocalStorage();

    // show checkboxes column
    this.toggleCheckboxVisibility();

    // reset all checkbox selection
    this.resetAllCheckboxesSelection();

    if (!this.selectedBrand || !this.selectedBrand.id) {
      // deskId can't exists without a selected brand
      this.$scope.$broadcast('table:filter:remove', 'desk');
    }
    this.tableParams.reload();
  }

  /**
   * Notify bar when brand dropdown updated.
   *
   * Notify cleared or added.
   *
   * @return none.
   */
  notifyFilterBarOnBrandFilterUpdate(newBrand: Brand) {
    if (_.isNil(_.get('id', newBrand))) {
      this.opRemoveFilterFromTable$.next(BRAND_FILTER_NAME);
      return;
    }

    const newFilter = {};
    newFilter[BRAND_FILTER_NAME] = newBrand;
    const msg = {
      newFilter,
      clearName: BRAND_FILTER_NAME,
    };

    this.opAddFilterToTable$.next(msg);
  }

  /**
   * Returns channel to subscribe for updates of specific element
   *
   * @param {int} elementId
   * @returns {string}
   */
  buildChannel(elementId) {
    return `user.${this.user.id}.${this.socketService.channelRoot}.${elementId}`;
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService() {
    return this.customersSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.customers';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return <ICollectionRestNg<Customer>>this.customers;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.contactsTable.ngTable;
  }

  /**
   * on toggle checkbox on the edit columns option (enable / disable column),
   * then update the NgTable on the first place with checkbox column
   * needed for assign customers to user.
   */
  onToggleColumn() {
    this.prepareTableColumnLists();
  }

  /**
   * prepare table column list for table & edit columns dropdown
   */
  prepareTableColumnLists() {
    // get only allowed columns for selected brand platform type (common & binary or common & forex)
    this.tableColumns = this.settings.contactsTable.colsList.filter(
      (column) => {
        // if a permissionKey was defined for this column, enforce it
        if (
          column.permissionKey &&
          !this.authenticationService.isValidPermission(column.permissionKey)
        ) {
          return false;
        }

        if (!this.shouldAddColumnByPlatformToColumnList(column)) {
          return false;
        }
        return true;
      },
    );

    this.filteredSettings = [...this.tableColumns];

    if (this.userVoipBrands && this.userVoipBrands.size > 0) {
      this.showClickToCallColumn();
    }

    this.prepareTableColumnListsFromUserSetting();
  }

  prepareTableColumnListsFromUserSetting() {
    const blockUiInstance = this.blockUI.instances.get('contactTableBlock');
    blockUiInstance.start();

    this.userSettingsService
      .getSettingWithoutDefault(USER_SETTING_CONTACT_DASHBOARD_COLUMNS_KEY)
      .then((data) => {
        if (_.isNil(data) || _.isNil(data.value.data)) {
          return;
        }

        const tableColumns = data.value.data
          .map((item) => {
            const column = this.filteredSettings.find(
              (c) => c.fieldName === item,
            );
            if (!_.isNil(column)) {
              column.show = true;
            }

            return column;
          })
          .filter((x) => !_.isNil(x));

        this.tableColumns = tableColumns;
        this.toggleCheckboxVisibility();
        if (this.userVoipBrands && this.userVoipBrands.size > 0) {
          this.showClickToCallColumn();
        }

        blockUiInstance.stop();
      })
      .finally(() => blockUiInstance.stop());
  }

  /**
   * hide column by field name
   * @param {String} field
   */
  hideColumn(field) {
    this.tableColumns.find((col) => col.field === field).show = false;
  }

  removeFieldFromUserSettings(field) {
    const columnNames = this.tableColumns
      .map((column) => column.fieldName)
      .filter((name) => name !== field);

    this.userSettingsService
      .getSetting(USER_SETTING_CONTACT_DASHBOARD_COLUMNS_KEY, columnNames)
      .then((data) =>
        this.userSettingsService
          .setSettingValue(data.id, {
            version: USER_SETTING_CONTACT_DASHBOARD_COLUMNS_VERSION,
            data: columnNames,
          })
          .then((d) => this.prepareTableColumnListsFromUserSetting()),
      );
  }

  streamUserCoulomnChanged() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === USER_SETTINGS_UPDATED),
      rx.tap(() => {
        this.prepareTableColumnListsFromUserSetting();
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Decide if column should be added to column list based on it's platform.
   * @param column - column setting info.
   * @return indication if to add to list.
   */
  shouldAddColumnByPlatformToColumnList(column): boolean {
    if (column.type === 'common') {
      return true;
    }

    if (!column.platformCodes && column.type === this.getPlatformType()) {
      return true;
    }

    if (
      column.platformCodes &&
      _.intersection(
        column.platformCodes,
        this.getPlatformsCodes(this.selectedBrand),
      ).length > 0
    ) {
      return true;
    }

    return false;
  }

  /**
   * Get all platforms codes for sent brand.
   * @brandParam - brand
   * @return array of connected platforms codes to the brand.
   */
  getPlatformsCodes(brandParam: Brand) {
    return _.flow([
      (brand) => _.defaultTo({}, brand),
      (brand) => _.defaultTo([], brand.platformConnections),
      (conns) => conns.map((conn) => conn.platform),
      (platforms) => platforms.map((platform) => platform.code),
    ])(brandParam);
  }

  /**
   * Show the  "Click To Call" column
   */
  showClickToCallColumn() {
    this.tableColumns.find((col) => col.field === 'ctc').show = true;
  }

  /**
   * Set user voip brands set and add a "Click to call" column if the user has voip settings
   * for at least one brand.
   */
  setVoipSettings() {
    this.usersServiceInst
      .getVoipBrandsSet(<number>this.user.id)
      .then((brandsSet) => {
        this.userVoipBrands = brandsSet;
        if (brandsSet.size > 0) {
          this.showClickToCallColumn();
        }
      });
  }

  /**
   * on reset All Selection Event
   *
   * can be override by a different logic
   */
  onResetAllSelection() {
    this.showSelectionBar = false; // hide assign user popup
  }

  /**
   *  on toggle all checkboxes event
   *
   *  can be override by a different logic
   */
  onToggleAllCheckbox() {
    this.showPopupAssignUsers(); // show popup tab Assign users
  }

  /**
   *  on toggle checkbox event
   *
   *  can be override by a different logic
   *
   *  @param {object} obj
   */
  onToggleCheckbox(obj) {
    this.showPopupAssignUsers(); // show popup tab Assign users
  }

  /**
   * show popup tab Assign users, only when user select at least one customer
   */
  showPopupAssignUsers() {
    const selectedItems = this.checkboxes.selectedToList;
    this.showSelectionBar = false;

    if (selectedItems.length > 0) {
      this.showSelectionBar = true;
    }
  }

  /**
   * Handle filters from URL 'filter' parameter
   * If the url contains a filter parameter, treat it as a quick filter and activate it.
   */
  handleUrlFilterParam() {
    const filterName = this.$stateParams.filter;
    // treat the 'filter' param as a quick filter (only these are supported now)
    if (filterName && this.filtersSettings[filterName]) {
      // in quick filters, we just need the filter name. so to filter by new contact it would be { newContacts: {} }
      this.replaceFilters({ [filterName]: {} });
    }
  }

  /**
   * sets tableParams to new number of rows
   * @param {number} numOfRows is the number of rows the table will have in every page
   * @returns {void}
   */
  setNumberOfRows(numOfRows): void {
    super.setNumberOfRows(numOfRows.count);
    // save to server
    this.userSettingsService.setSettingValue(
      this.userSettingsInstance.id,
      numOfRows.count,
    );
  }

  /**
   * Open add contact dialog.
   */
  openAddContactDialog() {
    this.modalService.open({
      component: 'prfAddContactDialog',
    });
  }

  /**
   * Export Table as CSV
   */
  streamExportTableCsv() {
    return rx.pipe(
      () => this.exportTableCsvAction,
      rx.switchMap(() => {
        const displayingColumns = this.tableColumns.filter(
          (col) => col.show && col.reportFields,
        );
        const columnsTitles = displayingColumns.map(
          (col) => col.title || col.reportTitle,
        );
        return rx.obs
          .from((this.$translate(columnsTitles) as any) as Promise<string>)
          .pipe(
            rx.map((translations) => ({ translations, displayingColumns })),
          );
      }),
      rx.map(({ translations, displayingColumns }) =>
        displayingColumns.reduce(
          (acc, col) => [
            ...acc,
            {
              title: translations[col.title || col.reportTitle],
              fields: col.reportFields,
              template: col.reportFieldsTemplate || null,
            },
          ],
          [],
        ),
      ),
      rx.tap((columns) => {
        console.log('OutGoingRequestedData: ', this.outgoingRequestUrl);
        const { pathname, query } = new Url(this.outgoingRequestUrl);

        this.prfCRMExportService()
          .setConfig({
            growlRef: 'exportCustomers',
            blockUiRef: 'exportCustomers',
          })
          .generateExportCustomerTableCsv(
            this.dataServiceInstance.resource,
            columns,
            `${pathname}${query}`,
          );
      }),
    )(null);
  }

  streamStateParamContactsImportId() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() => {
        return this.$stateParams.contactsImportId;
      }),
    )(null);
  }

  streamContactsImportFilter() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isInitTable$.pipe(rx.filter((initTable) => initTable)),
          this.streamStateParamContactsImportId(),
        ),
      rx.filter(([a, id]) => !_.isNil(id)),
      rx.switchMap(([a, id]) =>
        this.prfContactsImportsService()
          .setConfig({
            blockUiRef: 'contactTableBlock',
          })
          .expand(['resource'])
          .getOneWithQuery<IElementRestNg<ContactsImport>>(id),
      ),
      rx.map((contactsImport) =>
        (contactsImport as IElementRestNg<ContactsImport>).plain(),
      ),
      rx.map((contactsImport: ContactsImport) => {
        const newFilter = {};
        newFilter[CONTACTS_IMPORT_FILTER_NAME] = contactsImport;
        newFilter[
          CONTACTS_IMPORT_FILTER_NAME
        ].translatedName = `${contactsImport.id} - ${contactsImport.resource.name}`;
        return {
          newFilter,
          clearName: CONTACTS_IMPORT_FILTER_NAME,
        };
      }),
      rx.tap((msg) => this.opAddFilterToTable$.next(msg as any)),
      rx.tap(() => {
        // set first page for getData()
        this.tableParams.page(1);
        // reload table. changes will be reloaded to table due to requiredApiFilters()
        this.tableParams.reload();
      }),
    )(null);
  }

  onCellUpdate(
    updatedCustomer: Customer,
    currentCustomer: Customer,
    afterFn: () => void,
  ) {
    Object.assign(currentCustomer, updatedCustomer);

    afterFn();
  }

  switchOpenAddCommunicationPopup() {
    return rx.pipe(
      rx.switchMap((customer) =>
        this.communicationTypesService
          .getCommTypeComment()
          .then((commType) => ({ customer, commType })),
      ),
      rx.switchMap(({ customer, commType }) => {
        const modal = this.popupService.open({
          component: 'AddCallPopup',
          resolve: {
            communicationType: () => commType,
            customer: () => customer,
          },
        });
        return modal.result.then(
          (call) => ({ call, customer, isError: false }),
          () => ({ call: null, customer: null, isError: true }),
        ) as Promise<{ call: any; customer: any; isError: boolean }>;
      }),
      rx.filter(({ isError }) => !isError),
      rx.filter(
        ({ call }) =>
          !_.isNil(call) && call.method === CommunicationMethodCode.Manual,
      ),
      rx.tap(() => this.reloadTable()),
    );
  }

  streamAddNewCall() {
    return rx.pipe(
      () => this.addNewCallAction.pipe(rx.map((customer) => customer.plain())),
      this.switchOpenAddCommunicationPopup(),
      rx.switchMap(({ customer }) =>
        checkCrudPermission(
          PermissionNormalized.ContactsPersonalInfoStatus,
          this.PermPermissionStore,
        ).pipe(rx.map((permission) => ({ permission, customer }))),
      ),
      rx.filter(({ permission }) => permission.isUpdate),
      rx.switchMap(({ customer }) => {
        const modal = this.openCustomerStatusUpdatePopup(customer);
        return (modal.result.then(
          () => ({ isError: false }),
          () => ({ isError: true }),
        ) as any) as Promise<{ isError: boolean }>;
      }),
      rx.filter(({ isError }) => !isError),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamOpenEditColumnsPopup() {
    return rx.pipe(
      () => this.editColumnsAction,
      rx.tap(() => {
        this.modalService.open({
          component: 'prfTableColumnsPopup',
          size: 'xlg',
          resolve: {
            defaultTableColumns: () => dashboardSettings.contactsTable.colsList,
            userSettingsKey: () => USER_SETTING_CONTACT_DASHBOARD_COLUMNS_KEY,
            userSettingVersion: () =>
              USER_SETTING_CONTACT_DASHBOARD_COLUMNS_VERSION,
          },
        });
      }),
    )(null);
  }

  openCustomerStatusUpdatePopup(customer: Customer) {
    return this.popupService.open({
      controller: 'CustomerStatusUpdatePopupController',
      template: statusUpdateTemplate,
      data: {
        customer,
      },
    });
  }

  getCustomerLinkData(routeId, index, total) {
    return {
      total,
      id: routeId,
      userId: this.tokensService.getCachedUser().id,
      currentOffset: this.getCurrentOffsetByIndex(index),
      guid: this.prfAppTag.murmur,
      navigationId: generateUuid(),
      sort: Object.keys(this.tableParams.sorting()),
      order: Object.values(this.tableParams.sorting()),
      timestamp: moment().valueOf(),
    };
  }

  getCurrentOffsetByIndex(index) {
    const page = this.tableParams.page();

    if (page === 1) {
      return index;
    }

    return this.tableParams.count() * (page - 1) + index;
  }

  /**
   * Assign customer to user, by user selection per page.
   * Passed as a callback to the 'user assign dropdown' component.
   *
   * @param user - the user which will be assigned to customer. if null, user will be unassigned
   * @param desk - selected desk
   */
  assignToUser(user: User, desk: Desk, customer: Customer): Promise<void> {
    return this.customersService()
      .setConfig({ blockUiRef: 'assignToPopup' })
      .patchElement(customer.id, {
        deskId: desk.id,
        userId: user ? user.id : null,
      })
      .then(() => {
        customer.desk = desk;
        customer.user = user;
      });
  }

  streamAllBrandsOption() {
    return this.mainBrandSelectDataFetched$.pipe(
      rx.map((brands) => brands.find((b) => _.isNil(b.id))),
      shareReplayRefOne(),
    );
  }

  /**
   * Generate stream - Sync main brand filter from bar remove action.
   *
   * @return Observable of the stream.
   */
  streamSyncFilterRemovedByFilterBarUi() {
    return rx.pipe(
      () => this.tableFilterRemovedByUi$,
      rx.filter((filterName) => filterName === BRAND_FILTER_NAME),
      rx.withLatestFrom(this.allBrandsOption$),
      rx.tap(([a, allBrandsOption]) => {
        this.selectedBrand = allBrandsOption as any;
      }),
    )(null);
  }

  /**
   * Generate stream - Sync main brand filter from bar add action.
   *
   * @return Observable of the stream.
   */
  streamSyncFilterAddedByFilterBarEvent() {
    return rx.pipe(
      () => this.tableFilterAddedByEvent$,
      rx.filter((filter) => filter[BRAND_FILTER_NAME]),
      rx.map((filter) => filter[BRAND_FILTER_NAME]),
      rx.tap((brand) => (this.selectedBrand = brand)),
    )(null);
  }

  /**
   * Generate stream - Sync main brand dropdown by table applied filter cache on start.
   *
   * @return Observable of the stream.
   */
  streamSyncMainBrandFilterByTableAppliedCached() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.tableFilterAppliedFromSavedCache$.pipe(
            rx.filter((filtersState) => filtersState[BRAND_FILTER_NAME]),
            rx.map((filtersState) => filtersState[BRAND_FILTER_NAME].value),
          ),
          this.mainBrandSelectDataFetched$,
        ),
      rx.filter(([brand, isDataFetched]) => !_.isNil(isDataFetched)),
      rx.tap(([brand, a]) => (this.selectedBrand = brand)),
    )(null);
  }

  streamSyncMainBrandFilterByTableSavedFilters() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.savedFilterChanged$,
          this.mainBrandSelectDataFetched$,
        ),
      rx.filter(([filters, isDataFetched]) => !_.isNil(isDataFetched)),
      rx.withLatestFrom(this.allBrandsOption$),
      rx.tap(([[filters, brands], allBrandsOption]) => {
        const brandFilter = filters[BRAND_FILTER_NAME];
        if (_.isNil(brandFilter)) {
          if (brands.length > 2) {
            this.selectedBrand = allBrandsOption as any;
            return;
          }

          this.selectedBrand = brands[1] as any;
          this.notifyFilterBarOnBrandFilterUpdate(this.selectedBrand);
          return;
        }

        const brand = filters[BRAND_FILTER_NAME].value;
        this.selectedBrand = brand;
      }),
    )(null);
  }

  /**
   * Generate stream - Sync main brand filter from bar clear all action.
   *
   * @return Observable of the stream.
   */
  streamSyncMainBrandFilterByBarClearingFilters() {
    return rx.pipe(
      () => this.tableFiltersClearedByBar$,
      rx.withLatestFrom(
        this.allBrandsOption$,
        this.mainBrandSelectDataFetched$,
      ),
      rx.tap(([a, allBrandsOption, brands]) => {
        if (brands.length > 2) {
          this.selectedBrand = allBrandsOption as any;
          return;
        }

        this.selectedBrand = brands[1] as any;
        this.notifyFilterBarOnBrandFilterUpdate(this.selectedBrand);
      }),
    )(null);
  }
}

export const ContactsDashboardComponent = {
  template,
  controller: ContactsDashboardController,
  controllerAs: 'vm',
  bindings: {
    onTableInit: '&',
  },
};
