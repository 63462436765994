import { Permissions } from '~/source/common/models/permission-structure';

export const bundlesReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          entryDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'contact.bundles.ENTRY_DATE',
        field: 'entryDate',
        fieldName: 'entryDate',
        fieldFormat: '{{ entryDate }}',
        reportFields: ['entryDate'],
        filterable: true,
        sortable: 'entryDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.bundles.EXPIRY_DATE',
        field: 'expiryDate',
        fieldName: 'expiryDate',
        fieldFormat: '{{ expiryDate }}',
        reportFields: ['expiryDate'],
        filterable: true,
        sortable: 'expiryDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'customerTradingAccountsAuditLogsTable.TRADING_ACCOUNT_ID',
        field: 'tradingAccountId',
        fieldName: 'tradingAccountId',
        fieldFormat: '{{ tradingAccountId }}',
        reportFields: ['tradingAccountId'],
        filterable: false,
        sortable: 'tradingAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'pendingPositionForex.fields.ORDER_ID',
        field: 'bundleOrderId',
        fieldName: 'bundleOrderId',
        fieldFormat: '{{ bundleOrderId }}',
        reportFields: ['bundleOrderId'],
        filterable: false,
        sortable: 'bundleOrderId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.BUNDLE_NAME',
        field: 'name',
        fieldName: 'name',
        fieldFormat: '{{ name }}',
        reportFields: ['name'],
        filterable: false,
        sortable: 'name',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.INVESTMENT',
        field: 'investment',
        fieldName: 'investment',
        fieldFormat: '{{ investment | number: 2 }}',
        reportFields: ['investment'],
        filterable: true,
        sortable: 'investment',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.LEVERAGE',
        field: 'leverage',
        fieldName: 'leverage',
        fieldFormat: '{{ leverage }}',
        reportFields: ['leverage'],
        filterable: true,
        sortable: 'leverage',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.bundles.PAYOUT',
        field: 'payout',
        fieldName: 'payout',
        fieldFormat: '{{ payout | number : 2 }}',
        reportFields: ['payout'],
        filterable: true,
        sortable: 'payout',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'marketing.table.PNL',
        field: 'pnl',
        fieldName: 'pnl',
        fieldFormat: '{{ pnl | number : 2 }}',
        reportFields: ['pnl'],
        filterable: true,
        sortable: 'pnl',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.PNL_BASIC',
        field: 'pnlBasic',
        fieldName: 'pnlBasic',
        fieldFormat: '{{ pnlBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['pnlBasic'],
        filterable: true,
        sortable: 'pnlBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.POSITIONS_VOLUME',
        field: 'positionsVolume',
        fieldName: 'positionsVolume',
        fieldFormat: '{{ positionsVolume | number : 2 }}',
        reportFields: ['positionsVolume'],
        filterable: true,
        sortable: 'positionsVolume',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMPLOYEE',
        field: 'accountManager',
        fieldName: 'accountManager',
        fieldFormat: '{{ accountManager }}',
        reportFields: ['accountManager'],
        filterable: true,
        sortable: 'accountManager',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.GROUP',
        field: 'groupName',
        fieldName: 'groupName',
        fieldFormat: '{{ groupName }}',
        reportFields: ['groupName'],
        filterable: false,
        sortable: 'groupName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'isReal',
        fieldFormat: '{{ isReal }}',
        reportFields: ['isReal'],
        filterable: true,
        sortable: 'isReal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'STATUS',
        field: 'bundleStatusCode',
        fieldName: 'status',
        fieldFormat: '{{ status }}',
        reportFields: ['status'],
        filterable: true,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{ department }}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
