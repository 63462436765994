import { Permissions } from '~/source/common/models/permission-structure';

export const ftdsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          firstDepositDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        reportsRealContacts: {
          state: {
            isActive: true,
          },
        },
      },
    },
    colsList: [
      {
        title: 'contact.FTD_DATE',
        field: 'firstDepositDate',
        fieldName: 'firstDepositDate',
        fieldFormat: '{{ firstDepositDate }}',
        reportFields: ['firstDepositDate'],
        filterable: true,
        sortable: 'firstDepositDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PHONE',
        field: 'phone',
        fieldName: 'phone',
        fieldFormat: '{{ phone }}',
        reportFields: ['phone'],
        filterable: false,
        sortable: 'phone',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.AMOUNT',
        field: 'firstDepositAmount',
        fieldName: 'firstDepositAmount',
        fieldFormat: '{{ firstDepositAmount | number : 2 }}',
        reportFields: ['firstDepositAmount'],
        filterable: true,
        sortable: 'firstDepositAmount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.METHOD',
        field: 'reportsFirstPaymentMethod',
        fieldName: 'firstPaymentMethod',
        fieldFormat: '{{ firstPaymentMethod }}',
        reportFields: ['firstPaymentMethod'],
        filterable: true,
        sortable: 'firstPaymentMethod',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CLEARING_COMPANY',
        field: 'reportsClearingCompany',
        fieldName: 'clearingCompany',
        fieldFormat: '{{ clearingCompany }}',
        reportFields: ['clearingCompany'],
        filterable: true,
        sortable: 'clearingCompany',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FOUR_LAST_DIGITS',
        field: 'fourLastDigits',
        fieldName: 'fourLastDigits',
        fieldFormat: '{{ fourLastDigits }}',
        reportFields: ['fourLastDigits'],
        filterable: false,
        sortable: 'fourLastDigits',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.OWNER',
        field: 'reportsOwner',
        fieldName: 'owner',
        fieldFormat: '{{ owner }}',
        reportFields: ['owner'],
        filterable: true,
        sortable: 'owner',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'deposits.OWNER_DESK',
        field: 'ownerDesk',
        fieldName: 'ownerDesk',
        fieldFormat: '{{ ownerDesk }}',
        reportFields: ['ownerDesk'],
        filterable: true,
        sortable: 'ownerDesk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'campaignOffer',
        fieldName: 'campaignOffer',
        fieldFormat: '{{ campaignOffer }}',
        reportFields: ['campaignOffer'],
        filterable: false,
        sortable: 'campaignOffer',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMPLOYEE',
        field: 'reportsByEmployee',
        fieldName: 'employee',
        fieldFormat: '{{ employee }}',
        reportFields: ['employee'],
        filterable: true,
        sortable: 'employee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.BALANCE',
        field: 'balance',
        fieldName: 'balance',
        fieldFormat: '{{ balance | number : 2}}',
        reportFields: ['balance'],
        filterable: true,
        sortable: 'balance',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_DEPOSIT_BASIC',
        field: 'depositBasicTotal',
        fieldName: 'depositBasicTotal',
        fieldFormat: '{{ depositBasicTotal | baseCurrencyFormat : 2 : true }}',
        reportFields: ['depositBasicTotal'],
        filterable: true,
        sortable: 'depositBasicTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.NET_DEPOSITS',
        field: 'netDeposit',
        fieldName: 'netDeposit',
        fieldFormat: '{{ netDeposit | number : 2 }}',
        reportFields: ['netDeposit'],
        filterable: true,
        sortable: 'netDeposit',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.PLATFORM_ID',
        field: 'externalAccountId',
        fieldName: 'externalAccountId',
        fieldFormat: '{{ externalAccountId }}',
        reportFields: ['externalAccountId'],
        filterable: false,
        sortable: 'externalAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.DEPOSIT_EXTERNAL_ID',
        field: 'externalDepositId',
        fieldName: 'externalDepositId',
        fieldFormat: '{{ externalDepositId }}',
        reportFields: ['externalDepositId'],
        filterable: false,
        sortable: 'externalDepositId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_CALL_DATE',
        field: 'lastCallDate',
        fieldName: 'lastCallDate',
        fieldFormat: '{{ lastCallDate }}',
        reportFields: ['lastCallDate'],
        filterable: true,
        sortable: 'lastCallDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'filters.LAST_DEPOSIT_DATE',
        field: 'lastDepositDate',
        fieldName: 'lastDepositDate',
        fieldFormat: '{{ lastDepositDate }}',
        reportFields: ['lastDepositDate'],
        filterable: true,
        sortable: 'lastDepositDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'filters.LAST_LOGIN_DATE',
        field: 'lastLoginDate',
        fieldName: 'lastLoginDate',
        fieldFormat: '{{ lastLoginDate }}',
        reportFields: ['lastLoginDate'],
        filterable: true,
        sortable: 'lastLoginDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_CALL_DATE',
        field: 'firstCallDate',
        fieldName: 'firstCallDate',
        fieldFormat: '{{ firstCallDate }}',
        reportFields: ['firstCallDate'],
        filterable: true,
        sortable: 'firstCallDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_COMMENT_DATE',
        field: 'firstCommentDate',
        fieldName: 'firstCommentDate',
        fieldFormat: '{{ firstCommentDate }}',
        reportFields: ['firstCommentDate'],
        filterable: true,
        sortable: 'firstCommentDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FTD_DAYS_COUNT',
        field: 'ftdDaysCount',
        fieldName: 'ftdDaysCount',
        fieldFormat: '{{ ftdDaysCount }}',
        reportFields: ['ftdDaysCount'],
        filterable: true,
        sortable: 'ftdDaysCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsByKyc',
        fieldName: 'kyc',
        fieldFormat: '{{ kyc }}',
        reportFields: ['kyc'],
        filterable: true,
        sortable: 'kyc',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_DEPOSIT_COUNT',
        field: 'totalDepositsCnt',
        fieldName: 'totalDepositsCnt',
        fieldFormat: '{{ totalDepositsCnt }}',
        reportFields: ['totalDepositsCnt'],
        filterable: true,
        sortable: 'totalDepositsCnt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        fieldFormat: '{{ real }}',
        reportFields: ['real'],
        filterable: true,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.STATUS',
        field: 'customerStatusName',
        fieldName: 'customerStatusName',
        fieldFormat: '{{ customerStatusName }}',
        reportFields: ['customerStatusName'],
        filterable: true,
        sortable: 'customerStatusName',
        removable: true,
        show: true,
        type: 'customerStatusName',
      },
      {
        title: 'reports.BIRTHDAY',
        field: 'birthday',
        fieldName: 'birthday',
        fieldFormat: '{{ birthday }}',
        reportFields: ['birthday'],
        filterable: true,
        sortable: 'birthday',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CASH_PNL',
        field: 'cashPnl',
        fieldName: 'cashPnl',
        fieldFormat: '{{ cashPnl | number : 2 }}',
        reportFields: ['cashPnl'],
        filterable: true,
        sortable: 'cashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{ department }}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_EQUITY',
        field: 'equityTotal',
        fieldName: 'equityTotal',
        fieldFormat: '{{ equityTotal | number : 2 }}',
        reportFields: ['equityTotal'],
        filterable: true,
        sortable: 'equityTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_CALL_USER',
        field: 'firstCallUser',
        fieldName: 'firstCallUser',
        fieldFormat: '{{ firstCallUser }}',
        reportFields: ['firstCallUser'],
        filterable: true,
        sortable: 'firstCallUser',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FIRST_DEPOSIT_AMOUNT_BASIC',
        field: 'firstDepositAmountBasic',
        fieldName: 'firstDepositAmountBasic',
        fieldFormat:
          '{{ firstDepositAmountBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['firstDepositAmountBasic'],
        filterable: true,
        sortable: 'firstDepositAmountBasic',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.OPEN_TRADE_COUNT',
        field: 'openTradeCount',
        fieldName: 'openTradeCount',
        fieldFormat: '{{ openTradeCount }}',
        reportFields: ['openTradeCount'],
        filterable: true,
        sortable: 'openTradeCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'PHONE2',
        field: 'phone2',
        fieldName: 'phone2',
        fieldFormat: '{{ phone2 }}',
        reportFields: ['phone2'],
        filterable: false,
        sortable: 'phone2',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_BONUS',
        field: 'totalBonuses',
        fieldName: 'totalBonuses',
        fieldFormat: '{{ totalBonuses | number : 2 }}',
        reportFields: ['totalBonuses'],
        filterable: true,
        sortable: 'totalBonuses',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.WITHDRAWABLE_BALANCE',
        field: 'withdrawableBalance',
        fieldName: 'withdrawableBalance',
        fieldFormat: '{{ withdrawableBalance | number : 2}}',
        reportFields: ['withdrawableBalance'],
        filterable: true,
        sortable: 'withdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
