import ng, { IScope } from 'angular';
import moment from 'moment';

import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './confirm-ewallet-deposit-popup.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import EwalletsService from '~/source/management/brand-ewallet/services/ewallets.service';
import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import Ewallet from '~/source/common/models/ewallet';
import { TradingAccountTransactionStatusCode } from '@proftit/crm.api.models.enums';
import CustomersService from '~/source/contact/common/services/customers';
import { DEPOSIT_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { Entity, EntityWithCode } from '@proftit/crm.api.models.general';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

const styles = require('./confirm-ewallet-deposit-popup.component.scss');

export class ConfirmEwalletDepositPopupController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  resolve;
  close: () => void;
  blockUiKey = generateBlockuiId();

  addDepositAction = new rx.Subject<void>();

  ewallets$ = this.streamEwallets();
  modelFormGroup$ = this.streamModelFormGroup();

  /* @ngInject */
  constructor(
    readonly $scope: IScope,
    readonly ewalletsService: EwalletsService,
    readonly depositsSettings,
    readonly dateFormat,
    readonly customersService: () => CustomersService,
    readonly prfClientGeneralPubsub,
  ) {
    useStreams(
      [
        this.addDepositAction,
        this.ewallets$,
        this.modelFormGroup$.pipe(rx.switchMap((x) => x.value$)),
        this.streamAddDeposit(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamEwallets() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(this.ewalletsService.getListWithQuery())
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.map((ewallets: IElementRestNg<EntityWithCode>[]) =>
        ewallets.map((ewallet) => {
          return {
            ...ewallet.plain(),
            label: ewallet.name,
          };
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  idSelectionCompare = (a: Entity, b: Entity) => {
    return _.get(['id'], a) === _.get(['id'], b);
  };

  streamModelFormGroup() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => {
        const {
          transactionId,
          fromEwalletAddress,
          toEwalletAddress,
          ewallet,
        } = this.resolve.deposit.ewalletTransaction;
        const ewalletToSet = !_.isNil(ewallet)
          ? { ...ewallet, label: ewallet.name }
          : null;
        return new FormGroup({
          // passing an empty object to the date picker initializes its view to today's date
          receivedDate: new FormControl({}),
          amountApproved: new FormControl<number>(null),
          approvedFirstName: new FormControl<number>(null),
          approvedLastName: new FormControl<string>(null),
          ewallet: new FormControl<any>(ewalletToSet),
          transactionId: new FormControl<string>(transactionId),
          fromEwalletAddress: new FormControl<string>(fromEwalletAddress),
          toEwalletAddress: new FormControl<string>(toEwalletAddress),
          note: new FormControl<string>(null),
        } as any);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAddDeposit() {
    return rx.pipe(
      () => this.addDepositAction,
      rx.withLatestFrom(this.modelFormGroup$),
      rx.map(([a, formGroup]) => {
        const valueCopy = { ...formGroup.value };

        if (_.isEmpty(valueCopy.receivedDate)) {
          valueCopy.receivedDate = moment.utc().startOf('day');
        }

        const {
          ewallet,
          transactionId,
          fromEwalletAddress,
          toEwalletAddress,
          ...rest
        } = valueCopy;

        return {
          ...rest,
          transactionTransferEwallet: {
            transactionId,
            fromEwalletAddress,
            toEwalletAddress,
            ewalletId: ewallet.id,
          },
          receivedDate: valueCopy.receivedDate.format(
            this.dateFormat.MYSQL_DATETIME,
          ),
        };
      }),
      rx.switchMap((normalizedData) => {
        const dataToSend = {
          ...normalizedData,
          transactionStatusCode: TradingAccountTransactionStatusCode.Approved,
        };
        return rx.obs
          .from(
            this.customersService()
              .getDepositResource(
                this.resolve.customer.id,
                this.resolve.account.id,
                this.resolve.deposit.id,
              )
              .setConfig({ blockUiRef: this.blockUiKey })
              .patchWithQuery({
                ...dataToSend,
              })
              .then((res) => {
                /*
                 * emit event that will eventually will cause to
                 * deposits table to reload data from api
                 */
                this.$scope.$emit('depositsTableReload');
                this.prfClientGeneralPubsub.publish(DEPOSIT_STATUS_UPDATE);
                this.close();
              }),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ConfirmEwalletDepositPopupComponent = {
  template,
  controller: ConfirmEwalletDepositPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
