import { Permissions } from '~/source/common/models/permission-structure';

export const positionsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          entryDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'contact.positions.ENTRY_DATE',
        field: 'entryDate',
        fieldName: 'entryDate',
        fieldFormat: '{{ entryDate }}',
        reportFields: ['entryDate'],
        filterable: true,
        sortable: 'entryDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.PLATFORM_ID',
        field: 'externalAccountId',
        fieldName: 'externalAccountId',
        fieldFormat: '{{ externalAccountId }}',
        reportFields: ['externalAccountId'],
        filterable: false,
        sortable: 'externalAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'customerTradingAccountsAuditLogsTable.TRADING_ACCOUNT_ID',
        field: 'tradingAccountId',
        fieldName: 'tradingAccountId',
        fieldFormat: '{{ tradingAccountId }}',
        reportFields: ['tradingAccountId'],
        filterable: false,
        sortable: 'tradingAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TRADING_PLATFORM',
        field: 'tradingPlatform',
        fieldName: 'tradingPlatform',
        fieldFormat: '{{ tradingPlatform }}',
        reportFields: ['tradingPlatform'],
        filterable: false,
        sortable: 'tradingPlatform',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.ASSET',
        field: 'tradeAsset',
        fieldName: 'asset',
        fieldFormat: '{{ asset }}',
        reportFields: ['asset'],
        filterable: true,
        sortable: 'asset',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.POSITION_ORDER_ID',
        field: 'positionOrderId',
        fieldName: 'positionOrderId',
        fieldFormat: '{{ positionOrderId }}',
        reportFields: ['positionOrderId'],
        filterable: false,
        sortable: 'positionOrderId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.INVESTMENT',
        field: 'investment',
        fieldName: 'investment',
        fieldFormat: '{{ investment | number: 2 }}',
        reportFields: ['investment'],
        filterable: true,
        sortable: 'investment',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.INVESTMENT_BASIC',
        field: 'investmentBasic',
        fieldName: 'investmentBasic',
        fieldFormat: '{{ investmentBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['investmentBasic'],
        filterable: true,
        sortable: 'investmentBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.SIZE',
        field: 'size',
        fieldName: 'size',
        fieldFormat: '{{ size }}',
        reportFields: ['size'],
        filterable: true,
        sortable: 'size',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.ENTRY_RATE',
        field: 'entryRate',
        fieldName: 'entryRate',
        fieldFormat: '{{ entryRate }}',
        reportFields: ['entryRate'],
        filterable: true,
        sortable: 'entryRate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.EXPIRY_RATE',
        field: 'expiryRate',
        fieldName: 'expiryRate',
        fieldFormat: '{{ expiryRate }}',
        reportFields: ['expiryRate'],
        filterable: true,
        sortable: 'expiryRate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.EXPIRY_DATE',
        field: 'expiryDate',
        fieldName: 'expiryDate',
        fieldFormat: '{{ expiryDate }}',
        reportFields: ['expiryDate'],
        filterable: true,
        sortable: 'expiryDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.TYPE',
        field: 'positionType',
        fieldName: 'positionType',
        fieldFormat: '{{ positionType }}',
        reportFields: ['positionType'],
        filterable: false,
        sortable: 'positionType',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.STATUS',
        field: 'positionStatusForex',
        fieldName: 'status',
        fieldFormat: '{{ status }}',
        reportFields: ['status'],
        filterable: true,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.GROUP',
        field: 'forexGroup',
        fieldName: 'forexGroup',
        fieldFormat: '{{ forexGroup }}',
        reportFields: ['forexGroup'],
        filterable: false,
        sortable: 'forexGroup',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'riskManager.LEVERAGE',
        field: 'leverage',
        fieldName: 'leverage',
        fieldFormat: '{{ leverage }}',
        reportFields: ['leverage'],
        filterable: true,
        sortable: 'leverage',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.TAKE_PROFIT',
        field: 'takeProfit',
        fieldName: 'takeProfit',
        fieldFormat: '{{ takeProfit }}',
        reportFields: ['takeProfit'],
        filterable: true,
        sortable: 'takeProfit',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.STOP_LOSS',
        field: 'stopLoss',
        fieldName: 'stopLoss',
        fieldFormat: '{{ stopLoss }}',
        reportFields: ['stopLoss'],
        filterable: true,
        sortable: 'stopLoss',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.STOP_LOSS_AMOUNT',
        field: 'stopLossAmount',
        fieldName: 'stopLossAmount',
        fieldFormat: '{{ stopLossAmount | number : 2 }}',
        reportFields: ['stopLossAmount'],
        filterable: true,
        sortable: 'stopLossAmount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ORDER_RESULT',
        field: 'orderResult',
        fieldName: 'orderResult',
        fieldFormat: '{{ orderResult }}',
        reportFields: ['orderResult'],
        filterable: false,
        sortable: 'orderResult',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.bundles.PROFIT',
        field: 'profit',
        fieldName: 'profit',
        fieldFormat: '{{ profit | number: 2 }}',
        reportFields: ['profit'],
        filterable: true,
        sortable: 'profit',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.PROFIT_BASIC',
        field: 'profitBasic',
        fieldName: 'profitBasic',
        fieldFormat: '{{ profitBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['profitBasic'],
        filterable: true,
        sortable: 'profitBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.PAYOUT',
        field: 'payout',
        fieldName: 'payout',
        fieldFormat: '{{ payout | number: 2 }}',
        reportFields: ['payout'],
        filterable: true,
        sortable: 'payout',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.PAYOUT_BASIC',
        field: 'payoutBasic',
        fieldName: 'payoutBasic',
        fieldFormat: '{{ payoutBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['payoutBasic'],
        filterable: true,
        sortable: 'payoutBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'riskManager.SWAP',
        field: 'swap',
        fieldName: 'swap',
        fieldFormat: '{{ swap | number : 2 }}',
        reportFields: ['swap'],
        filterable: true,
        sortable: 'swap',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'marketing.table.PNL',
        field: 'pnl',
        fieldName: 'pnl',
        fieldFormat: '{{ pnl | number : 2 }}',
        reportFields: ['pnl'],
        filterable: true,
        sortable: 'pnl',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TRADES_COUNT',
        field: 'tradeCount',
        fieldName: 'tradeCount',
        fieldFormat: '{{ tradeCount }}',
        reportFields: ['tradeCount'],
        filterable: true,
        sortable: 'tradeCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_NAME',
        field: 'accountName',
        fieldName: 'accountName',
        fieldFormat: '{{ accountName }}',
        reportFields: ['accountName'],
        filterable: false,
        sortable: 'accountName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'brand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.OPEN_TRADE_COUNT',
        field: 'openTradeCount',
        fieldName: 'openTradeCount',
        fieldFormat: '{{ openTradeCount }}',
        reportFields: ['openTradeCount'],
        filterable: true,
        sortable: 'openTradeCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        fieldFormat: '{{ real }}',
        reportFields: ['real'],
        filterable: true,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_CALL_DATE',
        field: 'lastCallDate',
        fieldName: 'lastCallDate',
        fieldFormat: '{{ lastCallDate }}',
        reportFields: ['lastCallDate'],
        filterable: true,
        sortable: 'lastCallDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accounting.TURNOVER',
        field: 'turnover',
        fieldName: 'turnover',
        fieldFormat: '{{ turnover | number : 2 }}',
        reportFields: ['turnover'],
        filterable: true,
        sortable: 'turnover',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accounting.VOLUME',
        field: 'volume',
        fieldName: 'volume',
        fieldFormat: '{{ volume | number : 2 }}',
        reportFields: ['volume'],
        filterable: true,
        sortable: 'volume',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accounting.WIN_RATE',
        field: 'winrate',
        fieldName: 'winrate',
        fieldFormat: '{{ winrate }}',
        reportFields: ['winrate'],
        filterable: true,
        sortable: 'winrate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.INTERNAL_POSITION_ID',
        field: 'id',
        fieldName: 'id',
        fieldFormat: '{{ id }}',
        reportFields: ['id'],
        filterable: false,
        removable: true,
        sortable: 'id',
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.BUNDLE_NAME',
        field: 'bundleName',
        fieldName: 'bundleName',
        fieldFormat: '{{ bundleName }}',
        reportFields: ['bundleName'],
        filterable: false,
        sortable: 'bundleName',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contactsImportTable.fields.CREATED_AT',
        field: 'createdAt',
        fieldName: 'createdAt',
        fieldFormat: '{{ createdAt }}',
        reportFields: ['createdAt'],
        filterable: true,
        sortable: 'createdAt',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{ department }}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.LOSS_RATE',
        field: 'lossRate',
        fieldName: 'lossRate',
        fieldFormat: '{{ lossRate }}',
        reportFields: ['lossRate'],
        filterable: true,
        sortable: 'lossRate',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.PROFIT_RATE',
        field: 'profitRate',
        fieldName: 'profitRate',
        fieldFormat: '{{ profitRate }}',
        reportFields: ['profitRate'],
        filterable: true,
        sortable: 'profitRate',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'LAST_UPDATED_DATE',
        field: 'updatedAt',
        fieldName: 'updatedAt',
        fieldFormat: '{{ updatedAt }}',
        reportFields: ['updatedAt'],
        filterable: true,
        sortable: 'updatedAt',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
