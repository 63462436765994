import * as rx from '@proftit/rxjs';
import ActionsBarBase from '../../common/trading-account-actions-bar.controller';
import ModalService from '~/source/common/components/modal/modal.service';
import CustomersService from '~/source/contact/common/services/customers';
import hasResetPasswordFunctionality from '~/source/common/models/trading-account/has-reset-password-functionality';

import template from './trading-account-actions-bar-forex.html';
const style = require('./trading-account-actions-bar-forex.scss');

import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
import { MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { TRADING_ACCOUNT_STREAMER_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import PopupService from '~/source/common/components/modal/popup.service';

export class AccountActionsBarForexController extends ActionsBarBase {
  Permissions = Permissions;

  lifecycles$ = observeComponentLifecycles(this);

  customerIn$ = observeShareCompChange<Customer>(
    this.lifecycles$.onChanges$,
    'customer',
  );

  accountIn$ = observeShareCompChange<TradingAccount>(
    this.lifecycles$.onChanges$,
    'account',
  );

  isPrivateEwalletActive$ = this.streamIsPrivateEwalletActive();
  showToggleIsDemo$ = this.streamShowToggleIsDemo();

  /*@ngInject*/
  constructor(
    readonly growl: angular.growl.IGrowlService,
    readonly customersService: () => CustomersService,
    readonly modalService: ModalService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly popupService: PopupService,
  ) {
    super(growl, customersService);

    useStreams(
      [this.customerIn$, this.accountIn$, this.isPrivateEwalletActive$],
      this.lifecycles$.onDestroy$,
    );
  }

  $onDestroy() {}

  streamCustomerAccountTypePermissions() {
    return rx.pipe(
      () => this.customerIn$,
      rx.switchMap((customer) =>
        checkCrudPermission(
          PermissionNormalized.ContactsTradingAccountType,
          this.PermPermissionStore,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamAccountUpdatedByStreamer() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === TRADING_ACCOUNT_STREAMER_UPDATE),
      rx.withLatestFrom(this.accountIn$),
      rx.filter(([{ payload }, account]) => payload.accountId === account.id),
      rx.map(() => true),
      shareReplayRefOne(),
    )(null);
  }

  streamShowToggleIsDemo() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest({
          account: this.accountIn$,
          accountUpdated: this.streamAccountUpdatedByStreamer().pipe(
            rx.startWith(true),
          ),
          accountTypePerm: this.streamCustomerAccountTypePermissions(),
        }),
      rx.map(({ account, accountTypePerm }) => {
        if (account.isAccountActive) {
          return false;
        }

        return accountTypePerm.isUpdate;
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * This method is responsible for checking if a private eWallet is active for a specific customer and account.
   * It performs the following steps:
   * 1. It combines the latest values of `customerIn$` and `accountIn$` observables.
   * 2. It calls the `fetchCustomerEwallets` method with the customer ID and account ID as arguments.
   * 3. It checks if the returned eWallets array has any elements. If it does, it means a private eWallet is active.
   * 4. If an error occurs during the fetch operation, it catches the error and returns false.
   *
   * @returns {Observable<boolean>} An observable that emits true if a private eWallet is active, and false otherwise.
   */
  streamIsPrivateEwalletActive() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.customerIn$, this.accountIn$),
      rx.switchMap(([customer, account]) => {
        return this.fetchCustomerEwallets(customer.id, account.id)
          .then((ewallets) => {
            return ewallets.length > 0;
          })
          .catch(() => false);
      }),
      rx.map((hasWallet) => {
        return hasWallet;
      }),

      shareReplayRefOne(),
    )(null);
  }

  /**
   * This method is responsible for fetching eWallets associated with a specific customer and account.
   * It performs the following steps:
   * 1. It calls the `getAccountEwalletResource` method from the `customersService` service with the customer ID and account ID as arguments.
   * 2. It then calls the `getListWithQuery` method to retrieve the list of eWallets.
   * 3. It converts the fetched eWallets to a plain object using the `plain` method.
   *
   * @param {number} customerId - The ID of the customer for which to fetch the eWallets.
   * @param {number} accountId - The ID of the account for which to fetch the eWallets.
   * @returns {Promise<Object[]>} A promise that resolves to an array of eWallets associated with the specified customer and account.
   */
  fetchCustomerEwallets(customerId, accountId) {
    return this.customersService()
      .getAccountEwalletResource(customerId, accountId)
      .getListWithQuery()
      .then((ewallets) => {
        return ewallets.plain();
      });
  }

  /**
   * Change account type to the new account group
   * @param {object} group - new account group
   * @return {Promise} resolves when the server PATCH is successful
   */
  changeAccountGroup(group) {
    return this.tradingAccountResource
      .patchWithQuery({
        tradingAccountGroupId: group.id,
      })
      .then(() => {
        this.account.tradingAccountGroup = group;
      });
  }

  activateResetPasswordFlow() {
    this.modalService.open({
      component: 'prfResetPasswordDialog',
      resolve: {
        account: () => this.account,
      },
    });
  }

  hasResetPasswordFunctionality(account) {
    return hasResetPasswordFunctionality(account);
  }

  isAllowedToChangeCurrency() {
    if (this.account.isAccountActive) {
      return false;
    }

    if (MT4_PLATFORMS.includes(this.account.platform.code)) {
      return false;
    }

    return true;
  }

  onChangeIsDemo(isDemo) {
    return this.tradingAccountResource
      .patchWithQuery({
        isDemo,
      })
      .then(() => {
        this.account.isDemo = isDemo;
      });
  }

  openPrivateEwalletPopup() {
    this.popupService.open({
      component: 'prfPrivateEwalletPopup',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }
}

export default {
  template,
  controller: AccountActionsBarForexController,
  bindings: {
    customer: '<',
    account: '<',
  },
};
